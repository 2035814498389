<template>
	<div>
		<div class="box box_mm">
			<div class="box_main">
				<div
					style="height: 70vh;overflow-y: auto;padding-right: 0.5rem;padding-bottom: 40px;box-sizing: border-box;">
					<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
						<el-form-item label="提案标题" prop="name" style="width: 700px;">
							<el-input v-model="ruleForm.name"></el-input>
						</el-form-item>
						<el-form-item label="提案类别" prop="lei_id">
							<el-select v-model="ruleForm.lei_id" filterable placeholder="请选择提案类别" style="width: 190px;">
								<el-option v-for="(item,index) in tianlei" :key="index" :label="item.name"
									:value="item.id"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="提案形式" prop="taxs">
							<el-select v-model="ruleForm.taxs" placeholder="请选择提案形式" style="width: 190px;">
								<el-option v-for="(item,index) in tianxs" :key="index" :label="item"
									:value="item"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="组织名称" v-if="ruleForm.taxs=='界别、小组、联组'||ruleForm.taxs=='党派、人民团体、专门委员会'"
							prop="mingcheng" style="width: 700px;">
							<el-input v-model="ruleForm.mingcheng"></el-input>
						</el-form-item>
						<el-form-item label="案由分析" prop="content">
							<el-input type="textarea" v-model="ruleForm.content" rows="6"
								style="width: 600px;"></el-input>
						</el-form-item>
						<el-form-item label="建议办法" prop="way">
							<el-input type="textarea" v-model="ruleForm.way" rows="6" style="width: 600px;"></el-input>
						</el-form-item>
						<el-form-item label="上传照片">
							<el-upload :action="baseurl+'/api/login/upload'" :headers="{
							  		token:$store.state.userinfo.token
							  	}" 
								:on-change="getimglist"
								:file-list="fileList_img"
								list-type="picture-card" :on-preview="handlePictureCardPreview" :on-remove="handleRemove">
								<i class="el-icon-plus"></i>
							</el-upload>
							<el-dialog :visible.sync="dialogVisible">
								<img width="100%" :src="dialogImageUrl" alt="">
							</el-dialog>
						</el-form-item>
						<el-form-item label="上传附件" style="display: flex;align-items: center;">
							<el-upload
							  class="upload-demo"
							  :action="baseurl+'/api/login/upload'" :headers="{
							  		token:$store.state.userinfo.token
							  	}"
							  :on-success="handleAvatarSuccess"
							  :on-remove="handleChange"
							  :file-list="fileList">
							  <el-button size="small" type="primary">点击上传</el-button>
							</el-upload>
						</el-form-item>
						<el-form-item label="联名人" required>
							<div style="display: flex;align-items: center;">
								<el-button type="primary" @click="select">选择</el-button>
								
							</div>
							<div>
								<span style="color: #999;">已选人员：</span>{{user_name}}
							</div>
						</el-form-item>
					</el-form>
				</div>

				<div style="display: flex;align-content: center;margin-top: 30px;">
					<el-button @click="goback">取 消</el-button>
					<el-button type="primary" @click="submitForm">确 定</el-button>
				</div>
			</div>
		</div>



		<selectuser ref="myselectuser" @selectedry="selectedry"></selectuser>
	</div>

</template>

<script>
	import selectuser from '@/components/select_user.vue'
	export default {
		components: {
			selectuser
		},
		data() {
			return {
				tianlei: [], //提案类别
				tianxs: ['个人', '界别、小组、联组', '党派、人民团体、专门委员会'], //提案形式
				ruleForm: {
					name: '',
					lei_id: '',
					taxs: '',
					mingcheng: "",
					content: '',
					way: '',
				},
				rules: {
					name: [{
						required: true,
						message: '请输入',
						trigger: 'blur'
					}],
					lei_id: [{
						required: true,
						message: '请选择',
						trigger: 'change'
					}],
					taxs: [{
						required: true,
						message: '请选择',
						trigger: 'change'
					}],
					mingcheng: [{
						required: true,
						message: '请输入',
						trigger: 'blur'
					}],
					content: [{
						required: true,
						message: '请输入',
						trigger: 'blur'
					}],
					way: [{
						required: true,
						message: '请输入',
						trigger: 'blur'
					}],
				},
				fileList_img:[],//上传照片
				fileList:[],//附件列表
				dialogImageUrl: '',
				dialogVisible: false,
				user_id: '',//联名人
				user_name: '',//联名人
			}
		},
		created() {
			this.id = this.$route.query.id
			this.baseurl = this.$URL
			this.getleibie() //获取提案类别
		},
		methods: {
			select() {
				let cxidlist = this.user_id.split(',')
				let cxnamelist = this.user_name.split(',')
				let list = []
				cxidlist.forEach((item, index) => {
					let obj = {
						name: cxnamelist[index],
						id: Number(item)
					}
					list.push(obj)
				})
				this.$refs.myselectuser.selectuser = list
				this.$refs.myselectuser.dialogVisible = true
			},
			selectedry(e) {
				this.user_id = e.map((item) => {
					return item.id
				})
				this.user_id = this.user_id.join(',')
				this.user_name = e.map((item) => {
					return item.name
				})
				this.user_name = this.user_name.join(',')
			},

			submitForm() {
				console.log(this.fileList_img)
				// if (this.addnewinfo.action == 1 && !this.addnewinfo.module_id) {
				// 	this.$message.warning('请选择板块')
				// 	return
				// }
				// if (this.addnewinfo.action == 2 && !this.addnewinfo.url) {
				// 	this.$message.warning('请填写url')
				// 	return
				// }
				// if (!this.addnewinfo.image) {
				// 	this.$message.warning('请上传图片')
				// 	return
				// }
				// this.$refs.addnewinfo.validate((valid) => {
				// 	if (valid) {
				// 		this.$post({
				// 			url: '/admin/banner/edit',
				// 			params: this.addnewinfo
				// 		}).then((res) => {
				// 			this.$message.success('编辑成功')
				// 			this.$router.push('/system/swiper_mng')
				// 		})
				// 	} else {
				// 		console.log('error submit!!');
				// 		return false;
				// 	}
				// })
			},
			handleAvatarSuccess(res, file) {
				let obj ={
					name:res.file_name,
					url:res.fullurl,
					id:res.id
				}
				this.fileList.push(obj)
			},
			handleChange(file, fileList) {
				this.fileList = fileList			    
			},
			getimglist(e,list){
				if(e.status=='success'){
					this.fileList_img = list.map((item)=>{
						let obj = {
							name:item.name?item.name:item.response.file_name,
							url:item.url?item.url:item.response.fullurl,
							id:item.id?item.id:item.response.id
						}
						return obj
					})
				}				
			},
			handleRemove(file, fileList) {
				this.fileList_img = fileList.map((item)=>{
					let obj = {
						name:item.name,
						url:item.url,
						id:item.id
					}
					return obj
				})
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			getleibie() {
				this.$post({
					url: '/api/lei/index',
					params: {
						p: 1,
						size: 9999
					}
				}).then((res) => {
					this.tianlei = res.list
				})
			},
			goback() {
				this.$router.push('/system/tian_list')
			}
		}
	}
</script>

<style lang="scss" scoped>
	.box_main {
		background-color: #fff;
		padding: 15px 55px;
		width: 100%;
		box-sizing: border-box;
		min-height: 100%;
	}

	.search_box {
		display: flex;
		align-items: center;
		font-size: 0.16rem;

		.search_item {
			display: flex;
			align-items: center;
			margin-right: 0.5rem;

		}
	}

	.box_cont {
		margin-top: 20px;
	}

	.btn_list {
		display: flex;
		align-items: center;
	}

	.tc_title {
		font-size: 0.18rem;
		font-weight: bold;
		color: #333;
		position: relative;
		padding-left: 20px;
		margin-bottom: 20px;
	}

	.tc_title:after {
		content: '';
		position: absolute;
		height: 100%;
		width: 4px;
		left: 0px;
		background-color: rgb(26, 144, 253);
	}

	::v-deep td {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell>.cell {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell.is-leaf {
		background-color: rgb(242, 242, 242);
		color: #777;
	}

	::v-deep .el-table th.el-table__cell.is-leaf,
	.el-table td.el-table__cell {
		border-bottom: none !important;
	}

	::v-deep .el-table td.el-table__cell {
		border-bottom: none !important;
	}

	::v-deep .el-table {
		margin-top: 10px;
		border-left: none !important;
	}

	::v-deep .el-table__fixed {
		border-bottom: none;
	}

	::v-deep .el-table::before {
		background-color: #fff !important;
	}

	::v-deep .el-table__fixed::before {
		background-color: #fff !important;
	}

	::v-deep .el-dialog__header {
		display: flex !important;
	}

	::v-deep .el-dialog__footer span {
		display: flex !important;
		justify-content: space-around;
		width: 100%;
	}

	::v-deep .el-form-item__content {
		display: flex;
		flex-direction: column;
	}

	::v-deep .el-form-item__content {
		margin-left: 0 !important;
	}

	::v-deep .el-table__fixed-right::before {
		background-color: #fff !important;
	}

	.item_class {
		display: flex;
		align-items: center;
		width: 100%;
		box-sizing: border-box;

		.el-form-item {
			margin-right: 150px;
		}
	}

	::v-deep.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>